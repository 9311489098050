<template>
  <div>
    <div
      class="consume-bar margin-top-bottom-twenty box-shadow-light-grey border-radius-6 padding-right-left-5"
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class=""
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/message/mediaResource/media"
          @click.native="handleTabClick('/message/mediaResource/media')"
          >图文</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/message/mediaResource/resource"
          @click.native="handleTabClick('/message/mediaResource/resource')"
          >素材</el-menu-item
        >
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view
        :accountChannelId.sync="accountChannelId"
        :relationId.sync="relationId"
        :channelList="channelList"
      ></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: ["accountChannelId", "relationId", "channelList"],
  name: "consume",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.consume-bar{
  background:#fff;
  /deep/ .el-menu-item,
.el-submenu__title {
  height:34px;
}
}
.el-menu--horizontal > .el-menu-item{
  height: 52px;
  line-height: 52px;
}
.consume-part{
  box-shadow: 0px 0px 6px 0px #F1F4F6;
}

</style>
